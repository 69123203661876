import React, { useState } from "react";
import copy from "copy-text-to-clipboard";
import { tint, shade, readableColor } from "polished";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { twMerge } from "tailwind-merge";

const ColorTintsShades: React.FC = () => {
  const [color, setColor] = useState("#1A4052"); // Default color
  const [numTints, setNumTints] = useState(5); // Default number of tints
  const [numShades, setNumShades] = useState(5); // Default number of shades
  const [snackbarIsVisible, setSnackbarIsVisible] = useState(false);
  const [randomIsDisabled, setRandomIsDisabled] = useState(false);

  // Generate tints and shades arrays
  const generateColors = (baseColor: string, tints: number, shades: number) => {
    const tintsArray = Array.from({ length: tints }, (_, index) =>
      tint((index + 1) / (tints + 1), baseColor),
    );

    const shadesArray = Array.from({ length: shades }, (_, index) =>
      shade((index + 1) / (shades + 1), baseColor),
    );

    return { tintsArray, shadesArray };
  };

  const { tintsArray, shadesArray } = generateColors(
    color,
    numTints,
    numShades,
  );

  const textClass = (color: string) => {
    if (color) {
      const classes =
        "text-center text-sm font-light uppercase tracking-wider absolute bottom-2 right-3";
      const luminance = readableColor(color);
      return twMerge(
        classes,
        luminance === "#000" ? "text-zinc-800" : "text-zinc-200",
      );
    }
  };

  const randomColor = () => {
    setRandomIsDisabled(true);
    const code = Math.floor(Math.random() * 2 ** 24)
      .toString(16)
      .padStart(6, "0");
    setColor(`#${code}`);
    setRandomIsDisabled(false);
  };

  const tintMinus = () => {
    if (numTints > 1) {
      setNumTints(numTints - 1);
    }
  };

  const tintPlus = () => {
    setNumTints(numTints + 1);
  };

  const shadeMinus = () => {
    if (numShades > 1) {
      setNumShades(numShades - 1);
    }
  };

  const shadePlus = () => {
    setNumShades(numShades + 1);
  };

  const copyToClipboard = (text: string) => {
    copy(text);
    setSnackbarIsVisible(true);
    setTimeout(() => {
      setSnackbarIsVisible(false);
    }, 3000);
  };

  const snackbarClass = () => {
    const defaultClass = `absolute right-6 top-6 rounded-lg bg-sky-300 px-8 py-6 shadow-lg transition-all duration-500`;
    return twMerge(
      defaultClass,
      snackbarIsVisible ? "opacity-100" : "translate-x-[120%] opacity-0",
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent, callback: () => void) => {
    if (event.key === "Enter" || event.key === " ") {
      callback();
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="mb-6 mt-10 text-center text-5xl font-black">
        Color Tints and Shades Generator
      </h1>
      <p className="mx-auto mb-10 max-w-lg text-center text-lg font-semibold text-zinc-500">
        This page allows you to generate tints and shades of a chosen color. You
        can input a color, set the number of tints and shades, and view the
        results. The tool helps in creating color palettes and understanding
        color variations.
      </p>

      {/* Input: Color */}
      <div className="mb-4 text-center">
        <div className="space-x-4">
          <input
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)}
            className="mb-4 rounded-full border border-gray-300 px-3 py-2 text-center uppercase"
          />
          <button
            className="rounded-full border border-zinc-300 px-5 py-2 transition-all duration-300 hover:border-zinc-300 hover:bg-zinc-300"
            onClick={randomColor}
            disabled={randomIsDisabled}
          >
            Randomise
          </button>
        </div>
        <div
          className="relative mb-10 flex h-40 items-center justify-center rounded-xl"
          style={{ backgroundColor: color }}
        >
          <p className={textClass(color)}>{color}</p>
        </div>
      </div>

      {/* Display Tints */}
      <div className="mb-10">
        <div className="mx-auto max-w-lg">
          <h2 className="mb-2 text-2xl font-semibold">Tints</h2>
          <p className="mb-6 font-semibold opacity-70">
            Tints are lighter variations of a color, created by mixing the
            original color with white. They maintain the same hue but have
            reduced saturation and increased lightness.
          </p>

          <label className="mb-2 block text-center font-medium">
            Number of Tints
          </label>
          <div className="mb-6 flex items-center justify-center">
            <button
              className="rounded-full border border-zinc-300 px-5 py-2 transition-all duration-300 hover:border-zinc-300 hover:bg-zinc-300"
              onClick={tintMinus}
            >
              <ChevronLeft size={16} />
            </button>
            <span className="mx-4 font-bold">{numTints}</span>
            <button
              className="rounded-full border border-zinc-300 px-5 py-2 transition-all duration-300 hover:border-zinc-300 hover:bg-zinc-300"
              onClick={tintPlus}
            >
              <ChevronRight size={16} />
            </button>
          </div>
        </div>
        <div className="flex h-32 overflow-hidden rounded-xl">
          <div
            role="button"
            tabIndex={0}
            className="height-full relative flex flex-1 cursor-pointer px-3 py-2"
            style={{ backgroundColor: color }}
            onClick={() => copyToClipboard(color)}
            onKeyDown={(e) => handleKeyDown(e, () => copyToClipboard(color))}
          >
            <p
              className={`${
                readableColor(color) === "#fff"
                  ? "text-zinc-200"
                  : "text-zinc-800"
              } absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center font-semibold opacity-0 transition-all duration-300 hover:opacity-50`}
            >
              Click to copy
            </p>
            <p className={textClass(color)}>{color}</p>
          </div>
          {tintsArray.map((tintColor, index) => (
            <div
              key={index}
              role="button"
              tabIndex={0}
              className="height-full relative flex flex-1 cursor-pointer px-3 py-2"
              style={{ backgroundColor: tintColor }}
              onClick={() => copyToClipboard(tintColor)}
              onKeyDown={(e) =>
                handleKeyDown(e, () => copyToClipboard(tintColor))
              }
            >
              <p
                className={`${
                  readableColor(tintColor) === "#fff"
                    ? "text-zinc-200"
                    : "text-zinc-800"
                } absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center font-semibold opacity-0 transition-all duration-300 hover:opacity-50`}
              >
                Click to copy
              </p>
              <p className={textClass(tintColor)}>{tintColor}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Display Shades */}
      <div>
        <div className="mx-auto max-w-lg">
          <h2 className="mb-2 text-2xl font-semibold">Shades:</h2>
          <p className="mb-6 font-semibold opacity-70">
            Shades are darker variations of a color, created by mixing the
            original color with black. They maintain the same hue but have
            increased saturation and decreased lightness.
          </p>
        </div>

        <label className="mb-2 block text-center font-medium">
          Number of Shades
        </label>
        <div className="mb-6 flex items-center justify-center">
          <button
            className="rounded-full border border-zinc-300 px-5 py-2 transition-all duration-300 hover:border-zinc-300 hover:bg-zinc-300"
            onClick={shadeMinus}
          >
            <ChevronLeft size={16} />
          </button>
          <span className="mx-4 font-bold">{numShades}</span>
          <button
            className="rounded-full border border-zinc-300 px-5 py-2 transition-all duration-300 hover:border-zinc-300 hover:bg-zinc-300"
            onClick={shadePlus}
          >
            <ChevronRight size={16} />
          </button>
        </div>
        <div className="flex h-32 overflow-hidden rounded-xl">
          <div
            role="button"
            tabIndex={0}
            className="height-full relative flex flex-1 cursor-pointer px-3 py-2"
            style={{ backgroundColor: color }}
            onClick={() => copyToClipboard(color)}
            onKeyDown={(e) => handleKeyDown(e, () => copyToClipboard(color))}
          >
            <p
              className={`${
                readableColor(color) === "#fff"
                  ? "text-zinc-200"
                  : "text-zinc-800"
              } absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center font-semibold opacity-0 transition-all duration-300 hover:opacity-50`}
            >
              Click to copy
            </p>
            <p className={textClass(color)}>{color}</p>
          </div>
          {shadesArray.map((shadeColor, index) => (
            <div
              key={index}
              role="button"
              tabIndex={0}
              className="height-full relative flex flex-1 cursor-pointer items-center justify-center"
              style={{ backgroundColor: shadeColor }}
              onClick={() => copyToClipboard(shadeColor)}
              onKeyDown={(e) =>
                handleKeyDown(e, () => copyToClipboard(shadeColor))
              }
            >
              <p
                className={`${
                  readableColor(shadeColor) === "#fff"
                    ? "text-zinc-200"
                    : "text-zinc-800"
                } absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center font-semibold opacity-0 transition-all duration-300 hover:opacity-50`}
              >
                Click to copy
              </p>
              <p className={textClass(shadeColor)}>{shadeColor}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={snackbarClass()}>
        <p>Copied to clipboard</p>
      </div>
    </div>
  );
};
export default ColorTintsShades;
